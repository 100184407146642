import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import { ImCross } from 'react-icons/im';
import { checkNumberOfGuest } from '../utils/index';
import { useDispatch, useSelector } from 'react-redux'
import AvalibalityFlexiDatePopup from './checkAvailability/avalibalityFlexiDatePopup';
import AvailibilityDateRangePopup from '../common/checkAvailability/availibilityDateRangePopup';
import AvailabilityRecurring from './checkAvailability/availabilityRecurring';
import { scheduleModalOpenTrue } from '../actions/experienceBookingAction'
import AvailabilityPackage from './checkAvailability/availabilityPackage';
import AvailabilitySpecific from './checkAvailability/availabilitySpecific';

const AvailabilityRequestConfirmPopUp = ({
    open,
    handleClose,
    guestClickHandler,
    children,
    amountHandleChange,
    data,
    availabilityData,
    confirmClick,
    amountError,
    amountLengthError,
    guestError,
    adultGuestError,
    scheduleError,
    amountValue
}) => {
    const {
        pack_title, ref_id
    } = data;
    const guestOpen = useSelector((state) => state.experienceBooking.guestModalOpenTrue);
    const adultsGuest = useSelector((state) => state.experienceBooking.adultsGuest);
    const childrenGuest = useSelector((state) => state.experienceBooking.childrenGuest);
    const infantGuest = useSelector((state) => state.experienceBooking.infantGuest);
    const [openFlexiDatePopup, setOpenFlexiDatePopup] = useState(false);
    const [openDateRange, setOpenDateRange] = useState(false);
    const [openRecurringModal, setOpenRecurringModal] = useState(false);
    const [openPackageCalanderModal, setOpenPackageCalanderModal] = useState(false);
    const [openSpecificCalanderModal, setOpenSpecificCalenderModal] = useState(false);
    const [popupData, setPopupData] = useState(availabilityData);
    const [scheduleDateError, setScheduleDateError] = useState(false);

    const dispatch = useDispatch();
    
    const scheduleClickHandler = () => {
        dispatch(scheduleModalOpenTrue(true))
        if (availabilityData.pack_schedule_type === "date_range") setOpenDateRange(true);
        else if (availabilityData.pack_schedule_type === "recurring") {
            setOpenRecurringModal(true)
        } else if (availabilityData.pack_schedule_type === "package") setOpenPackageCalanderModal(true);
        else if (availabilityData.pack_schedule_type === "specific_dates") setOpenSpecificCalenderModal(true);
        else {

            setOpenFlexiDatePopup(true);
        }
    };

    const DateRangeDoneHandler = (data) => {
        setPopupData({ ...popupData, schedule: data })
        setOpenDateRange(false);

    };

    const flexiDateCalanderDonedHandler = (date) => {
        setOpenFlexiDatePopup(false);
        const changedFormat = date.map(date => moment(date, 'DD-MM-YYYY').format('MM/DD/YYYY'))
        const sorted = changedFormat.sort((a,b) => new Date(a).getTime() - new Date(b).getTime() );
        const selectedDatesList = sorted.map(date => moment(date, 'MM/DD/YYYY').format('DD-MM-YYYY'))
        setPopupData({ ...popupData, schedule: selectedDatesList })
    };

    const specificCalenderDoneModal = (date) => {
        setOpenSpecificCalenderModal(false);
        setPopupData({ ...popupData, schedule: date })
    };

    const recurringCalanderDondHandler = (date) => {
        setOpenRecurringModal(false);
        setPopupData({ ...popupData, schedule: date });
    };

    const packaegDateCalanderDondHandler = (date) => {
        setOpenPackageCalanderModal(false);
        setPopupData({ ...popupData, schedule: date });
    };

    const moreClickHandler = () => {
        setOpenFlexiDatePopup(true); 
    };

    return (
        <Dialog open={open}
            PaperProps={{
                style: {
                    height: "auto",
                    maxWidth: "430px"
                },
            }}
        >
            <div style={{ position: "relative" }}>
                <div style={{ marginTop: "35px", marginBottom: "25px" }}>
                    <h4 style={{ marginLeft: "25px" }}>Confirm Request</h4>
                    <span style={{ position: "absolute", right: 35, top: 50 }}
                        onClick={handleClose}>
                        <ImCross />
                    </span>
                </div>
                <form>
                    <div className="message-box" style={{ width: "370px" }}>
                        <p style={{ textAlign: 'center', color: "#2196F3" }}>{pack_title}</p>
                        <p style={{ textAlign: 'center', fontWeight: "500" }}>Ref. ID: {ref_id}</p>
                        <div className="contact-details-holder" style={{ justifyContent: 'center' }}>
                            <div className="contact-info " style={{ width: "250px" }}>
                                <div className="title">
                                    <p>Requested for</p>
                                </div>
                                <div className="contact-details" style={{ height: "auto",textAlign:"center" }}>
                                    <p className="email" style={{display:"inline-block", width:"100%"}}>
                                        <input style={{ width: "100%", border: "none", outline: "none", textAlign: "center" }} value={
                                                Array.isArray(popupData.schedule)? popupData.schedule.slice(0,2 ).join(", ")
                                                :
                                                (typeof popupData.schedule === 'object' && popupData.schedule !== null && popupData.schedule.to !== "" ? `${moment(popupData.schedule.from, 'DD-MM-YYYY').format('DD-MM-YYYY')} ${ popupData.schedule.to ? `to ${moment(popupData.schedule.to, "DD-MM-YYYY").format("DD-MM-YYYY")}`: ""}` : typeof popupData.schedule === 'object' && popupData.schedule !== null && popupData.schedule.to === "" ? `${popupData.schedule.from}` : (popupData.schedule === null ? "N/A" : popupData.schedule))
                                            }

                                         onClick={scheduleClickHandler} />
                                    </p>
                                    {popupData?.schedule?.length > 2 &&
                                      <span onClick={moreClickHandler} style={{marginLeft:"10px"}}>  +{popupData.schedule.length  - 2} more</span>
                                    }
                                    {scheduleError &&
                                        <p style={{ color: "red" }}>Please Select Schedule</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "15px" }} onClick={guestClickHandler}>
                            <input style={{ width: "100%", border: "none", outline: "none", textAlign: "center" }} value={
                                guestOpen === true ? checkNumberOfGuest(adultsGuest, childrenGuest, infantGuest) : checkNumberOfGuest(popupData.adults, popupData.children, popupData.infants)
                            } />
                            <div style={{ borderBottom: "1px solid black", width: "50%", marginLeft: "25%" }}></div>
                            <p style={{ textAlign: "center", marginTop: "10px", color: "#797979" }}>People</p>
                            {guestError &&
                                <p style={{ color: "red", textAlign:"center"}}>Please Enter No Of Guest</p>
                            }
                            {adultGuestError &&
                                <p style={{ color: "red", textAlign:"center" }}>Adult Guest Is Mandatory</p>
                            }
                        </div>
                    </div>
                    <div className="input--wrapper" style={{ width: "370px", margin: "20px 21px", textAlign: "center", marginTop: "20px" }}>
                        <label>Enter amount collected</label>
                        <div className="amountInput-holder">
                            <p>Rs</p>
                            <input
                                style={{ outline: "none" }}
                                type="text"
                                value={amountValue}
                                onChange={amountHandleChange}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </div>
                            {amountError &&
                                <p style={{ color: "red" }}>Please Enter Amount</p>
                            }
                            {amountLengthError && <p style={{ color: "red" }}>Amount should be less than six digit</p>}
                    </div>

                    <div className="cta-holder" style={{ marginBottom: "8px" }}>
                        <button type="button" className="btn cancel-btn" style={{ background: '#2196F3' }} onClick={() => confirmClick(popupData)}>Confirm booking</button>
                    </div>
                </form>
                {openFlexiDatePopup &&
                    <AvalibalityFlexiDatePopup
                        doneHandler={flexiDateCalanderDonedHandler}
                        flexiAvalabilityDate={popupData}
                    />
                }

                {openDateRange &&
                    <AvailibilityDateRangePopup
                        dateRangeAvailabilityData={popupData}
                        DoneHandler={DateRangeDoneHandler}
                    />
                }
                {openRecurringModal &&
                    <AvailabilityRecurring
                        donedHandler={recurringCalanderDondHandler}
                        recurringAvailabilityData={popupData}
                    />
                }
                {openPackageCalanderModal &&
                    <AvailabilityPackage
                        availabilityData={popupData}
                        DonedHandler={packaegDateCalanderDondHandler}
                    />
                }
                {openSpecificCalanderModal &&
                    <AvailabilitySpecific
                        specificAvailabilityData={popupData}
                        DonedHandler={specificCalenderDoneModal}
                    />
                }
            </div>
            <div>{children}</div>
        </Dialog>
    );
};
export default AvailabilityRequestConfirmPopUp;
