
import React from 'react';
import moment from 'moment';

import { CONTACT_INFO, MESSAGE, MORE_INFO, BOOK_FOR } from '../utils/constants';

const HolidaysListing = ({
  data,
  id,
  corporate,
  requestsType,
  locationsPreferred,
  bookedOn, requestedBy,
  schedule,
  noOfGuest,
  specialRequest,
  onClick,
  handleRefund,
  statusChangeHandler,
  value,
  requestType,
  statusValues,
  isHolidayBooking,
  isExperienceBooking,
  amount,
  packTitle,
  address,
  isHolidayRequest,
  contactInfoOnClick,
  messageTitle,
  optionOnclick,
  visaRequestType,
  firstTimeTraveller,
  isCancelled,
  isConfirmed,
  refCode,
  isShowMessage,
  status,
  voucher_info,
  section,
  isRefunded,
}) => {
  
  
  return (
    <div>
      <div className="enquiry-card-holder">
        <div className="organization-details" style={{ width: isShowMessage ? "450px" : "550px", marginRight: "0px" }}>
        <span style={{color: "#2196F3", fontSize:"12px", display:"block"}}>{refCode}</span>
          {isHolidayBooking || isExperienceBooking ? <h1>{packTitle}</h1> : <h1>{locationsPreferred}</h1>}
          <div className="info">
            {isHolidayBooking || isExperienceBooking ? <p>{address}</p> : <p>Requested on {bookedOn}</p>}
            <div >
              <span style={{ color: "#2196F3" }}>{requestType}</span>
              {isHolidayBooking || isExperienceBooking ? <span>Booked with {voucher_info?.voucher_code?`(${voucher_info?.voucher_code}  ${voucher_info?.voucher_amount?`of amount` +" " +  voucher_info?.voucher_amount:"package price"})  ${amount!=="N/A"? `&` +" "+ amount:""}`:amount!=="N/A"?amount:0}</span> : <span style={{ paddingLeft: "5px" }}>{requestedBy}</span>}
             
              <p style={{ color: "#DC7E27" }}>{firstTimeTraveller}</p>
              {isHolidayBooking || isExperienceBooking ? <p> <span style={{fontSize:"12px"}}>Booked on {moment(data?.created).format("Do MMMM, YYYY")}</span></p> : " "}
          
            </div>
          </div>
          {isConfirmed?<p style={{fontSize:"14px"}}><b>{data?.price?`Order Value:${data?.price}`:"Amount not mentioned"}</b></p>:""} 
        </div>
        {
          <div className="contact-info" style={{ visibility: `${visaRequestType === "Visa" ? "hidden" : ""}` }}>
            <div className="title">
              {isHolidayBooking || isExperienceBooking ?
                <p>{BOOK_FOR}</p> : <p>Requested For</p>}
            </div>
            <div className="contact-details">
              <p className="email">
                {schedule}
              </p>
              <p>
                {noOfGuest}
              </p>
            </div>
          </div>
        }
       {isShowMessage &&
        (<div className="contact-info">
          <div className="title">
            <p>{MESSAGE}</p>
          </div>
          <div className="contact-details">
            <p className="message-limit" title={messageTitle}>
              {specialRequest}
            </p>
          </div>
        </div>)}
        <div >
          {isHolidayRequest ?
            <p
              style={{
                backgroundColor: "#E7E7E7",
                padding: "5px",
                textAlign: "center",
                borderRadius: "5px",
                cursor: "pointer"
              }}
              onClick={contactInfoOnClick}
            >{CONTACT_INFO}</p>
            : <p
              style={{
                backgroundColor: "#E7E7E7",
                padding: "5px",
                textAlign: "center",
                borderRadius: "5px",
                cursor: "pointer"
              }}
              onClick={onClick}
            >{MORE_INFO}</p>}

         {(status==="booked"&&voucher_info?.voucher_code)&&<p style={{
                backgroundColor: "#E7E7E7",
                padding: "5px",
                textAlign: "center",
                borderRadius: "5px",
                cursor: "pointer"
              }}
              onClick={()=>handleRefund(id)}
            >Refund</p>
}
          {(isCancelled||isRefunded) ?
            <p
              style={{
                backgroundColor: "#fff",
                padding: "5px 30px",
                marginBottom:"0px",
                textAlign: "center",
                borderRadius: "5px",
                // cursor: "pointer",
                border:"1px solid #C4C4C4",
                width:"154px",
                height:"32px"
              }}
            >{isRefunded?"Refunded":"Cancelled"}</p> :
            <select
              id="status-dropdown-select"
              className="status-dropdown"
              value={value}
              onChange={(event) => statusChangeHandler(event, id, corporate, requestsType, data)}
            >
              {statusValues && statusValues.map(option => (
                <option className="greenColor" id="status-option" value={option.value} onClick={optionOnclick}>{option.name}</option>
              ))}
            </select>
          }
        </div>
      </div>
    </div>
  );
}

export default HolidaysListing;