import React from 'react'
import moment from 'moment';

const AvailabilityCard = ({
    statusValues,
    moreInfoClick,
    optionOnclick,
    packTitle,
    location,
    schedule,
    noOfGuest,
    specialRequest,
    statusChangeHandler,
    id,
    corporate,
    requestsType,
    data,
    value,
    amount,
    messageTitle,
    perPerson,
    refCode
}) => {

  console.log(schedule,"ssssss");

    return (
        <div>
        <div className="enquiry-card-holder">
          <div className="organization-details" style={{width:"550px", marginRight:"0px" }}>
          <span style={{color: "#2196F3", fontSize:"12px", display:"block"}}>{refCode}</span>
            <h1>{packTitle}</h1>
            <div className="info">
              <div >
              <span style={{fontSize:"12px", color:"#7A7A7A"}}>{location}</span>
              </div>
              <div>
                <span style={{fontSize:"12px"}}>{amount}</span> <span style={{color:"#7A7A7A", fontSize:"12px"}}>{perPerson}</span>
              </div>
              <div>
                <span style={{fontSize:"12px"}}>Booked on {moment(data?.created).format("Do MMMM, YYYY")}</span>

              </div>
              
            </div>
          </div>
          {
            <div className="contact-info" >
              <div className="title">
                <p>Requested For</p>
              </div>
              <div className="contact-details">
                <p className="email">
                  {schedule}
                </p>
                <p>
                  {noOfGuest}
                </p>
              </div>
            </div> 
          }
            {/* <div className="contact-info">
            <div className="title">
              <p>Message</p>
            </div>
            <div className="contact-details">
              <p className="message-limit" title={messageTitle}>
                {specialRequest}
              </p>
            </div>
          </div>  */}
          <div><p 
              style={{
              backgroundColor:"#E7E7E7",
              padding:"5px",
              textAlign:"center",
              borderRadius:"5px",
              cursor:"pointer"
            }}
              onClick={moreInfoClick}
            >More Info</p>
            <select
              id="status-dropdown-select"
              className="status-dropdown"
              value={value}
              // onChange={(event) => statusChangeHandler(event,id, corporate, requestsType, data)}
              onChange={(event) => statusChangeHandler(event,id, corporate, requestsType, data)}
            >
              {statusValues && statusValues.map(option => (
                <option className="greenColor" id="status-option" value={option.value} onClick={optionOnclick}>{option.name}</option>
              ))}
            </select> 
          </div>
        </div>         
    </div>
    )
}

export default AvailabilityCard
