import React, { useEffect, useState } from 'react'
import AvailabilityCard from '../../../common/availabilityCard';
import { corpAvailabilityStatusValue } from '../../../resources/status';
import { checkNumberOfGuest, isCorporate } from '../../../utils/index';
import {
  getAvailabilityRequestList,
  availabilitySearch,
  searchBooking,
  availabilityPage,
  getBookingConfirmedlist,
  getCancelledBookinglist,
  getFollowupList,
  updateBookingStatus
} from '../../../actions/bookingAction';
import { guestData, guestModalOpentrue } from '../../../actions/experienceBookingAction';
import { useDispatch, useSelector } from 'react-redux';
import CommonMoreInfoModel from '../../../common/commonMoreInfoModel';
import SearchBar from "./searchBar";
import Pagination from '../../../common/pagination';
import AvailabilityRequestConfirmPopUp from '../../../common/availabilityRequestConfirmPopUp';
import GuestModal from '../../../common/checkAvailability/guestModal';
import moment from 'moment';
import ClosedModal from '../closeRequestModal';
import { closeRequestInputVal } from '../../../actions/holidayRequestAction';

const Availability = () => {
  const [availabilityConfirmationPopupdata, setAvailabilityConfirmationPopupdata] = useState({});
  const [moreInfoData, setMoreInfoData] = useState({});
  const [openRequestConfirmedPopup, setOpenRequestConfirmedPopup] = useState(false);
  const [openMoreInfoModal, setOpenMoreInfoModal] = useState(false);
  const [openGuestModal, setOpenGuestModal] = useState(false);
  const [status, setStatus] = useState("");
  const [id, setId] = useState(null);
  const[iscopr,setIscorp]=useState()

  const [amountValue, setAmountValue] = useState("");
  const [scheduleError, setScheduleError] = useState(false);
  const [guestError, setGuestError] = useState(false);
  const [adultGuestError, setAdultGuestError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [amountLengthError, setAmountLengthError] = useState(false);
  const [adultCount, setAdultCount] = useState(0);
  const [childrenCount, setChildrenCount] = useState(0);
  const [infantCount, setInfantCount] = useState(0);

  const [requestModal, setRequestModal] = useState(false);
  const [closeRequestModalData, setCloseRequestModalData] = useState({});
  const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);
  const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);


  const availabilityRequest = useSelector((state) => state?.bookingListing?.availabilityRequest);
  const searchValue = useSelector((state) => state.bookingListing.corpAvailabilitySearchValue);
  const secId = useSelector((state) => state.bookingListing.getSecId);

  const pageNum = useSelector((state) => state.bookingListing.availabilityPage);
  const ffStartDate = useSelector((state) => state.bookingListing.filterStartDate);
  const ffEndDate = useSelector((state) => state.bookingListing.filterEndDate);
  const dispatch = useDispatch();
  const guestOpen = useSelector((state) => state.experienceBooking.guestModalOpenTrue);
  const adultsGuest = useSelector((state) => state.experienceBooking.adultsGuest);
  const childrenGuest = useSelector((state) => state.experienceBooking.childrenGuest);
  const infantGuest = useSelector((state) => state.experienceBooking.infantGuest);
  
  useEffect(() => {
    // dispatch(getAvailabilityRequestList())
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setGuestError(false);
      setAdultGuestError(false);
      setScheduleError(false);
      setAmountError(false);
      setAmountLengthError(false);
    }, 2000);
  }, [scheduleError, guestError, amountError, amountLengthError]);

  useEffect(() => {
    if (searchValue === "" && searchValue === undefined && ffStartDate === null &&
      ffEndDate === null && availabilityRequest?.next_page_id === null && availabilityRequest?.current_page > 1 && availabilityRequest?.length === 0) {
      dispatch(getAvailabilityRequestList(1, secId, "requested"));
      dispatch(availabilityPage(1));
    } else if (secId !== undefined && availabilityRequest?.current_page > 1 &&
      availabilityRequest?.next_page_id === null &&
      availabilityRequest?.results.length === 0) {
      dispatch(searchBooking(searchValue, "requested", secId, 1));
      dispatch(availabilityPage(1));
    } else if (searchValue !== "" && searchValue === undefined && availabilityRequest?.current_page > 1 &&
      availabilityRequest?.next_page_id === null &&
      availabilityRequest?.results.length === 0) {
      dispatch(availabilityPage(1));
      dispatch(searchBooking(searchValue, "requested", secId, 1));
    } else if (ffStartDate !== null && ffEndDate !== null && availabilityRequest?.current_page > 1 &&
      availabilityRequest?.next_page_id === null &&
      availabilityRequest?.results.length === 0) {
      dispatch(availabilityPage(1));
      dispatch(searchBooking(searchValue, "requested", secId, 1));
    } else if (secId !== undefined && searchValue !== "" && searchValue !== undefined &&
      ffStartDate !== null && ffEndDate !== null &&
      availabilityRequest?.current_page > 1 &&
      availabilityRequest?.next_page_id === null &&
      availabilityRequest?.results.length === 0) {
      dispatch(availabilityPage(1));
      dispatch(searchBooking(searchValue, "requested", secId, 1));
    }

  }, [availabilityRequest,]);

  useEffect(() => {
    setAdultCount(availabilityConfirmationPopupdata.adults);
    setChildrenCount(availabilityConfirmationPopupdata.children)
    setInfantCount(availabilityConfirmationPopupdata.infants)
  }, [availabilityConfirmationPopupdata]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => document.body.style.overflow = 'unset';
  }, [openMoreInfoModal])

  const statusChangeHandler = (event, id, corporate, requestsType, data) => {
    setCloseRequestModalData(data)
    setAvailabilityConfirmationPopupdata(data)
    dispatch(guestModalOpentrue(false))
    let status = event.target.value
    setStatus(status)
    setId(id)
    if (status === "confirmed") {
      setOpenRequestConfirmedPopup(true)
    }   else if(status === "cancelled")setRequestModal(true);
    else {
      dispatch(updateBookingStatus(id, status, secId,null, availabilityRefresh));
    }
  };

  const moreInfoClick = (data) => {
    setMoreInfoData(data);
    setOpenMoreInfoModal(true)
  };

  const availabilityRefresh = () => {
    setAmountValue("")
    if (searchValue !== "" && searchValue !== undefined) {
      dispatch(searchBooking(searchValue, "requested", secId, 1));
      dispatch(getBookingConfirmedlist(pageNum, secId));
      dispatch(getCancelledBookinglist(pageNum, secId));
    } else if (ffStartDate !== null && ffEndDate !== null) {
      dispatch(searchBooking(searchValue, "requested", secId, pageNum));
      dispatch(searchBooking(searchValue, "confirmed", secId, pageNum));
      dispatch(searchBooking(searchValue, "cancelled", secId, pageNum));
      dispatch(searchBooking(searchValue, "follow_up", secId, pageNum));
    } else {
      dispatch(getAvailabilityRequestList(pageNum, secId));
      dispatch(getBookingConfirmedlist(pageNum, secId));
      dispatch(getCancelledBookinglist(pageNum, secId));
      dispatch(getFollowupList(pageNum, secId))
    }
  };
  const handleChange = (e) => {
    let search = e.target.value
    dispatch(availabilitySearch(search))
    dispatch(searchBooking(searchValue, "requested", secId, 1));
  };

  const handleClose = () => {
    setOpenRequestConfirmedPopup(false)
    setAmountValue("")
  };

  const updateAvailabilityConfirmationData = (data) => {
    setAvailabilityConfirmationPopupdata(data)
  };

  const amountHandleChange = (e) => {
    setAmountValue(e.target.value)
  };
  const handlePagination = (e, value) => {
    if (searchValue !== "" && searchValue !== undefined || ffStartDate !== null && ffEndDate !== null || secId !== null) {
      dispatch(searchBooking(searchValue, 'requested', secId, 1));
      dispatch(availabilityPage(value));
    } else {
      dispatch(availabilityPage(value));
      dispatch(getAvailabilityRequestList(value, secId, "requested"));
    }
  };

  const confirmedHandler = (data) => {
    setGuestError(false);
    setAdultGuestError(false);
    setScheduleError(false);
    setAmountError(false);
    setAmountLengthError(false);
    const guestData = {
      adults: guestOpen === true ? adultsGuest : adultCount,
      children: guestOpen === true ? childrenGuest : childrenCount,
      infants: guestOpen === true ? infantGuest : infantCount
    }
    if (data.pack_schedule_type === "" && data.schedule.length === 0) setScheduleError(true);
    else if(adultsGuest === 0 && adultCount === 0 && childrenCount === 0 && childrenGuest === 0 && infantCount === 0 && infantGuest === 0) setGuestError(true)
    else if (adultsGuest === 0 && (childrenGuest > 0 || infantGuest > 0)) setAdultGuestError(true);
    else if (amountValue === "") setAmountError(true);
    else if (amountValue.length > 6) setAmountLengthError(true)
    else {
      dispatch(updateBookingStatus(data.id, status, secId,null, availabilityRefresh, data, guestData, amountValue));
      setOpenRequestConfirmedPopup(false);
      setAmountValue("");
    }
  }

  // guest functionality
  const adultGuestIncrementHandler = () => {
    setAdultCount(adultCount + 1)
  }

  const adultGuestIDecrementHandler = () => {
    if (adultCount <= 0) {
      setAdultCount(0);
    } else {
      setAdultCount(adultCount - 1)
    }
  };

  const childrensGuestIncrementHandler = () => {
    setChildrenCount(childrenCount + 1);
  }

  const childrensGuestIDecrementHandler = () => {
    if (childrenCount <= 0) {
      setChildrenCount(0);
    } else {
      setChildrenCount(childrenCount - 1)
    }
  };

  const infantGuestIncrement = () => {
    setInfantCount(infantCount + 1)
  };

  const infantGuestDecrement = () => {
    if (infantCount <= 0) {
      setInfantCount(0);
    } else {
      setInfantCount(infantCount - 1)
    }
  };
  const guestClickHandler = () => {
    setOpenGuestModal(true)
    dispatch(guestModalOpentrue(true));
  }
  const doneClickHandler = () => {
    dispatch(guestData(adultCount, childrenCount, infantCount))
    setOpenGuestModal(false);
  };
  const closeRequestHandler = () => {
 
    setIsclosingMessageBool(false)
    if(closeRequestMessage === "")setIsclosingMessageBool(true)
    else {
      dispatch(updateBookingStatus(id, status, secId,closeRequestMessage, availabilityRefresh));
      setRequestModal(false)
    }
    dispatch(closeRequestInputVal(''));
  };

const notToCancel=()=>{
    setRequestModal(false)
    setIsclosingMessageBool(false);

}
  //end///

  const availabilityDisplaydata = availabilityRequest && availabilityRequest?.results?.map(data => (
    <AvailabilityCard
      packTitle={data.pack_title}
      location={data?.sec_name && data?.sec_name == "International" ? data.location_country : data.address}
      schedule={Array.isArray(data.schedule) ? data.schedule.map((scheduleDate, index) => {
        if (data.schedule[index] === data.schedule[data.schedule.length - 1]) {
          return scheduleDate
        } else {
          return `${scheduleDate}, `
        }
      }) : (typeof data.schedule === 'object' && data.schedule !== null && data.schedule.to !== "" ? `${moment(data.schedule.from, 'DD-MM-YYYY').format('DD-MM-YYYY')} ${ data.schedule.to ? `to ${moment(data.schedule.to, "DD-MM-YYYY").format("DD-MM-YYYY")}`: ""}` : typeof data.schedule === 'object' && data.schedule !== null && data.schedule.to === "" ? `${data.schedule.from}` : (data.schedule === null ? "N/A" : data.schedule))}
      noOfGuest={checkNumberOfGuest(data.adults, data.children, data.infants)}
      specialRequest={data.special_request ? data.special_request : "N/A"}
      messageTitle={data.special_request}
      statusValues={corpAvailabilityStatusValue}
      refCode={data.ref_id? `#${data.ref_id}`:"NO ref id"}
      amount={data.amount === null ?"No amount" : `Amount to be paid ${data.amount}`}
      statusChangeHandler={statusChangeHandler}
      moreInfoClick={() => moreInfoClick(data)}
      value={data.booking_status}
      corporate={data.corporate}
      data={data}
      id={data.id}

    />
  ))

  return (
    <>
      <SearchBar
        searchVal={searchValue}
        status="requested"
        handleChange={handleChange}
      />
      {availabilityRequest?.results?.length === 0 && 
          <div className="enquiry-card-holder">No Record Found!</div>
        }
      {availabilityDisplaydata}
      <Pagination
        totalPage={availabilityRequest?.total_pages}
        page={pageNum}
        onChange={(event, value) => handlePagination(event, value)}
      />
      <CommonMoreInfoModel
        open={openMoreInfoModal}
        modalClose={() => setOpenMoreInfoModal(false)}
        moreInfoData={moreInfoData}
        isAvailabilityReq={true}
        amount={` ${moreInfoData.amount === null ? "No amount" :`Rs ${moreInfoData.amount}`}`}
        isCancellationMessage={false}
      
      />
      {openRequestConfirmedPopup &&
        <AvailabilityRequestConfirmPopUp
          open={openRequestConfirmedPopup}
          handleClose={handleClose}
          data={availabilityConfirmationPopupdata}
          guestClickHandler={guestClickHandler}
          changeData={updateAvailabilityConfirmationData}
          availabilityData={availabilityConfirmationPopupdata}
          scheduleError={scheduleError}
          guestError={guestError}
          adultGuestError={adultGuestError}
          amountError={amountError}
          amountLengthError={amountLengthError}
          amountValue={amountValue}
          confirmClick={confirmedHandler}
          amountHandleChange={amountHandleChange}
        >
          {openGuestModal &&
            <GuestModal
              adultGuestIncrementHandler={adultGuestIncrementHandler}
              adultGuestIDecrementHandler={adultGuestIDecrementHandler}
              childrensGuestIncrementHandler={childrensGuestIncrementHandler}
              childrensGuestIDecrementHandler={childrensGuestIDecrementHandler}
              infantGuestIncrement={infantGuestIncrement}
              infantGuestDecrement={infantGuestDecrement}
              doneClickHandler={doneClickHandler}
              adultCount={adultCount}
              childrenCount={childrenCount}
              infantCount={infantCount}
            />
          }
        </AvailabilityRequestConfirmPopUp>
      }
           {requestModal && 
                <ClosedModal 
                  open={requestModal}
                  handleClose={notToCancel}
                  data={closeRequestModalData}
                  isclosingMessage={isclosingMessageBool}
                  closeRequestHandler={closeRequestHandler}
                />
              }

    </>
  );
};

export default Availability;
